.package-intro {
    color: #d2d2d2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.package-intro :global {
    .header {
        padding: 8px 6px;
        font-size: 12px;
        .title {
            font-size: 20px;
            margin-bottom: 10px;
            .title-status {
                font-size: 12px;
                margin-left: 4px;
                color: #69e39f;
                border: 1px solid #69e39f;
                padding: 2px;
                vertical-align: middle;
            }
        }
        .website {
            font-style: italic;
        }
        .view-module {
            a{
                font-size: 14px;
                color: #466fd6;
            }
        }
        .rows-header{
            font-weight: 550;
        }
        .bottom-gap {
            margin-bottom: 4px;
        }
        .dot-gap {
            margin: 0 4px;
            font-size: 14px;
        }
    }
    .textarea-box {
        padding: 6px 4px;
        background: #383838;
        flex: 1;
    }
}