/* @import "~antd/dist/antd.css"; */
.timeline-editor {}

.timeline-editor-engine {
  width: 100%;
  height: 100%;
  display: flex;
}

.timeline-editor-engine .player-panel {
  width: 100%;
  /* max-width: 800px; */
  height: 100%;
  position: relative;
}

.timeline-editor-engine .player-panel .lottie-ani {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.timeline-editor-engine .timeline-editor {
  width: 100%;
  height: calc(100% - 32px);
}

.timeline-editor-engine .timeline-editor-action {
  height: 28px !important;
  top: 50%;
  transform: translateY(-50%);
}

.timeline-editor-engine .timeline-editor-action {
  border-radius: 4px;
}

.timeline-editor-engine .timeline-editor-action-effect-effect0 {
  background-color: #999999;
}

.timeline-editor-engine .timeline-editor-action-effect-effect1 {
  background-color: #7846a7;
}

.timeline-editor-engine .timeline-editor-action-effect-effect0,
.timeline-editor-engine .timeline-editor-action-effect-effect1 {
  cursor: pointer;
  background-position: bottom;
  background-repeat: repeat-x;
}

.timeline-editor-engine .timeline-editor-action-effect-effect0 .effect0,
.timeline-editor-engine .timeline-editor-action-effect-effect1 .effect0,
.timeline-editor-engine .timeline-editor-action-effect-effect0 .effect1,
.timeline-editor-engine .timeline-editor-action-effect-effect1 .effect1 {
  width: 100%;
  height: 100%;
  font-size: 10px;
  color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.timeline-editor-engine .timeline-editor-action-effect-effect0 .effect0-text,
.timeline-editor-engine .timeline-editor-action-effect-effect1 .effect0-text,
.timeline-editor-engine .timeline-editor-action-effect-effect0 .effect1-text,
.timeline-editor-engine .timeline-editor-action-effect-effect1 .effect1-text {
  margin-left: 4px;
  flex: 1 1 auto;
  text-align: center;
  display: flex;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.timeline-editor-engine .timeline-editor-action-effect-effect0 .timeline-editor-action-left-stretch::after,
.timeline-editor-engine .timeline-editor-action-effect-effect1 .timeline-editor-action-left-stretch::after {
  border-left: 7px solid rgba(255, 255, 255, 0.4);
}

.timeline-editor-engine .timeline-editor-action-effect-effect0 .timeline-editor-action-right-stretch::after,
.timeline-editor-engine .timeline-editor-action-effect-effect1 .timeline-editor-action-right-stretch::after {
  border-right: 7px solid rgba(255, 255, 255, 0.4);
}

.timeline-editor-engine .timeline-player {
  height: 32px;
  width: 100%;
  max-width: 800px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #3a3a3a;
  color: #ddd;
}

.timeline-editor-engine .timeline-player .play-control {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  background-color: #666;
  justify-content: center;
  align-items: center;
}

.timeline-editor-engine .timeline-player .time {
  font-size: 12px;
  margin: 0 20px;
  width: 70px;
}

.timeline-editor-engine .timeline-player .rate-control {
  justify-self: flex-end;
}

.timeline-editor-engine .timeline-player .rate-control .ant-select {
  width: 90px !important;
}

.timeline-editor-engine .timeline-player .rate-control .ant-select .ant-select-selector {
  border: none;
  box-shadow: none !important;
  background-color: transparent;
  color: #ddd;
}

.timeline-editor-engine .timeline-player .rate-control .ant-select .ant-select-selector .ant-select-selection-item {
  font-size: 12px;
  display: flex;
  justify-content: center;
}

.timeline-editor-engine .timeline-player .rate-control .ant-select-arrow {
  color: #ddd;
}


/* timelineSide */

.timeline-side {
  min-width: 300px;
  height: 100%;
  background-color: #3a3a3a;
}

.timeline-side .profile-name .file-name {
  color: rgb(190, 190, 190);
  margin: 10px 0;
}

.timeline-side .profile-name .track-ul .track {
  height: 32px;
  line-height: 32px;
  /* padding: 2px 0; */
  padding-left: 30px;
}

.timeline-side .profile-name .track-ul .track .track-name {
  color: #fff;
  line-height: 24px;
}

.timeline-side .profile-name .track-ul .track .ant-tree-node-content-wrapper {
  padding: 2px;
  line-height: 28px;
  font-size: 14px;
}

.timeline-side .profile-name .addTrack {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 200px;
  text-align: center;
  margin: 10px auto;
  font-size: 10px;
}

.timeline-side .profile-name .addTrack .property-list {
  display: none;
  width: 320px;
  min-height: 300px;
  border: 1px solid #666;
  position: absolute;
  top: 0;
  left: 101%;
  background-color: #3a3a3a;
  z-index: 100;
}

.timeline-side .profile-name .addTrack .property-list .ant-tree-title {
  width: 100%;
  display: inline-block;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.timeline-side .profile-name .addTrack .property-list .ant-tree-title .addBtn {
  height: 100%;
  width: 24px;
  padding: 0;
  border-radius: 0;
}

.timeline-side .profile-name .addTrack .property-list .ant-tree-node-content-wrapper {
  padding: 0;
}

.timeline-editor-engine .react-contexify {
  position: fixed;
  opacity: 0;
  user-select: none;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 10px 30px -5px rgb(0 0 0 / 30%);
  border-radius: 0;
  padding: 0;
  min-width: 200px;
  z-index: 100;
}

.timeline-editor-engine .react-contexify__item:hover {
  background-color: #1890ff;
}

.timeline-editor-engine .react-contexify__item__content {
  line-height: 20px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #333;
  position: relative;
  font-size: 12px;
  line-height: 20px;
}